export const REGISTER_SUCCESS = 'REGISTER_SUCCES'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const VERIFY_EMAIL = 'VERIFY_EMAIL'

export const FETCH_USER = 'FETCH_USER'
export const SET_MESSAGE = 'SET_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'

export const SET_SIDEBAR = 'SET_SIDEBAR'

export const CREATE_DEVICES = 'CREATE_DEVICES'
export const GET_DEVICES = 'GET_DEVICES'
export const GET_DEVICES_WITH_STATUS = 'GET_DEVICES_WITH_STATUS'
export const GET_LOG_WITH_DEVICE = 'GET_LOG_WITH_DEVICE'
export const DELETE_DEVICE = 'DELETE_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
